import React from "react"
import { Link } from "gatsby"
import { Button, Row, Col, Container, Image, Carousel } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/style.scss"

import mw1 from "../images/home-carousel/mw1.jpg"
import mw2 from "../images/home-carousel/mw2.jpg"
import mw3 from "../images/home-carousel/mw3.jpg"
import mw4 from "../images/home-carousel/mw4.jpg"
import mw5 from "../images/home-carousel/mw5.jpg"
import mw6 from "../images/home-carousel/mw6.jpg"
import mw7 from "../images/home-carousel/mw7.jpg"
import mw8 from "../images/home-carousel/mw8.jpg"
import mw9 from "../images/home-carousel/mw9.jpg"
import mw10 from "../images/home-carousel/mw10.jpg"
import mw11 from "../images/home-carousel/mw11.jpg"
import mw12 from "../images/home-carousel/mw12.jpg"
import mw13 from "../images/home-carousel/mw13.jpg"

import ha1 from "../images/home-carousel/ha1.jpg"
import ha2 from "../images/home-carousel/ha2.jpg"
import ha3 from "../images/home-carousel/ha3.jpg"
import ha4 from "../images/home-carousel/ha4.jpg"
import ha5 from "../images/home-carousel/ha5.jpg"
import ha6 from "../images/home-carousel/ha6.jpg"
import ha7 from "../images/home-carousel/ha7.jpg"
import ha8 from "../images/home-carousel/ha8.jpg"
import ha9 from "../images/home-carousel/ha9.jpg"
import ha10 from "../images/home-carousel/ha10.jpg"
import ha11 from "../images/home-carousel/ha11.jpg"

import bridal_bouquet from "../images/home-carousel/bridal-bouquet.jpg"
import bus_on_main from "../images/home-carousel/bus-on-main.jpg"
import cake from "../images/home-carousel/cake.jpg"
import ceremony from "../images/home-carousel/ceremony.jpg"
import father_daughter_dance from "../images/home-carousel/father-daughter-dance.jpg"
import flower_fairies from "../images/home-carousel/flower-fairies.jpg"
import horse1 from "../images/home-carousel/horse1.jpg"
import jenga from "../images/home-carousel/jenga.jpg"
import ladies_on_bridge from "../images/home-carousel/ladies-on-bridge.jpg"
import sacred_moment from "../images/home-carousel/sacred-moment.jpg"
import wilson from "../images/home-carousel/wilson.jpg"

const carouselImages = [
  mw1,
  mw2,
  mw3,
  mw4,
  mw5,
  mw6,
  mw7,
  mw8,
  mw9,
  mw10,
  mw11,
  mw12,
  mw13,
  bridal_bouquet,
  ha1,
  ha2,
  ha3,
  ha4,
  ha5,
  ha6,
  ha7,
  ha8,
  ha9,
  ha10,
  ha11,
  bus_on_main,
  cake,
  ceremony,
  father_daughter_dance,
  flower_fairies,
  horse1,
  jenga,
  ladies_on_bridge,
  sacred_moment,
  wilson,
]

const carousel = (
  <Carousel fade controls={false} indicators={false} interval={4000}>
    {carouselImages.map((image, i) => (
      <Carousel.Item key={i}>
        <Image
          fluid
          className="d-block mr-auto ml-auto"
          src={image}
          alt="carouselPhotos"
          style={{ maxHeight: "250px", borderRadius: "5px" }}
        />
      </Carousel.Item>
    ))}
  </Carousel>
)

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO
      title="Home"
      keywords={[`green`, `velvet`, `concierge`, `telluride`, `wedding`]}
    />

    <Container className="main-container">
      <Container className="carousel-container">
        <Row>
          <Col>{carousel}</Col>
        </Row>
      </Container>
      <Container className="main-container">
        <Row className="justify-content-center my-3" id="play-headline">
          <Col>Play like a local the minute you land in the mountains!</Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col className="home_content_bold">
            <strong>
              We want you to enjoy your time in the mountains, leave the
              planning to us!
            </strong>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col className="home_content">
            Traveling is stressful enough, let Green Velvet take some of the
            stress out your travels. We offer a safe, seamless vacation planning
            experience. Arrive at your mountain oasis via a private vehicle
            arranged on your behalf, a stocked pantry, dinner reservations
            handled, gear ready and waiting for you, a healthy stock for apres,
            and fireplace roaring. Browse our selection of services, catering to
            homeowners, renters, and locals alike.
          </Col>
        </Row>
        <Row>
          <Col className="home_content_bold">
            <strong>
              Make Green Velvet Concierge your personal Telluride concierge
              service.
            </strong>
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col className="home_content">
            We want to help you with every aspect of your Telluride experience!
            As long-time locals we know Telluride and we want to help you plan
            your next event. Whether it's a wedding, birthday celebration,
            anniversary, we have you covered. We work with the best vendors in
            the area to provide top-notch service. Let us help you make your
            event dreams become a reality in Telluride!
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-block to-contact"
                type="submit"
                variant="success"
              >
                Plan your trip with us!
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default IndexPage
